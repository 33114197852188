import { Fragment } from 'react'
import { isLoggedIn } from '../../../utils'
import UserLoggedInPro from './userLoggedInPro'
import UserLoggedIn from './userLoggedIn'
import UserNotLoggedIn from './userNotLoggedIn'
import useUserFromStorage from './hooks/useUserFromStorage'

export default function PadiHeaderAccountSection() {
  const isUserAuthenticated = isLoggedIn()
  const user = useUserFromStorage()

  return (
    <Fragment>
      {isUserAuthenticated && user ? (
        user?.isProDiver ? (
          <UserLoggedInPro user={user} />
        ) : (
          <UserLoggedIn user={user} />
        )
      ) : (
        <UserNotLoggedIn />
      )}
    </Fragment>
  )
}
