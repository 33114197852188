import { FormattedMessage } from 'react-intl'
import { createUrl } from '../../../localized-urls'
import PropTypes from 'prop-types'
import store from 'store2'
import cx from 'classnames'
import styles from './AccountMenu.module.scss'

export default function LoggedInMenu({ user }) {
  const {
    avatar,
  } = user

  const onLogout = () => {
    store.remove('user')
    store.remove('token')
  }
  return (
    <li className={cx('small-menu logged-user', styles.menu)}>
      <span>
        <img className='img-circle' src={avatar?.HeaderAvatar} />
      </span>
      <div className={cx('drop-down-menu', styles.dropDownMenu)}>
        <div className='group'>
          <h4>
            <FormattedMessage defaultMessage='Account' />
          </h4>
          <ul>
            <li>
              <a
                data-navigation-elements='data-navigation-elements'
                data-region-identifier='menu-travelmain-account'
                href={createUrl('bookings')}
              >
                <FormattedMessage defaultMessage='My Bookings' />
              </a>
              <i className='font-icons suitcase-icon' />
            </li>
            <li>
              <a
                data-navigation-elements='data-navigation-elements'
                data-region-identifier='menu-travelmain-account'
                href={createUrl('wishlist')}
              ><FormattedMessage defaultMessage='View my wishlist' />f</a>
              <i className='font-icons heart-icon' />
            </li>
            <li>
              <a
                data-navigation-elements='data-navigation-elements'
                data-region-identifier='menu-travelmain-account'
                href='https://logbook.diviac.com/'
              >
                <FormattedMessage defaultMessage='Go to my Diviac logbook' /></a>
            </li>
            <li>
              <a
                data-navigation-elements='data-navigation-elements'
                data-region-identifier='menu-travelmain-account'
                href={createUrl('preferences')}
              ><FormattedMessage defaultMessage='Manage my preferences' /></a>
            </li>
            <li>
              <a
                data-navigation-elements='data-navigation-elements'
                data-region-identifier='menu-travelmain-account'
                href={createUrl('logout')}
                onClick={onLogout}
              ><FormattedMessage defaultMessage='Logout' /></a>
            </li>
          </ul>
        </div>
      </div>
    </li>
  )
}

LoggedInMenu.propTypes = {
  user: PropTypes.shape({
    avatar: PropTypes.shape({
      HeaderAvatar: PropTypes.string,
    }),
  }),
}
