import LoggedInMenu from './LoggedInMenu'
import NonLoggedInMenu from './NonLoggedInMenu'
import { isLoggedIn } from '../../../utils'
import isEmpty from 'lodash/isEmpty'
import useUserFromStorage from '../padiHeader/hooks/useUserFromStorage'

export default function ToggleAccountMenu() {
  const user = useUserFromStorage()
  const isLogged = isLoggedIn()
  return isLogged && user && !isEmpty(user) ? (
    <LoggedInMenu user={user} />
  ) : (
    <NonLoggedInMenu />
  )
}
