import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { createPadiProLocalizedUrl } from '../../../localized-urls'

export default function UserLoggedInPro({ user }) {
  const { avatar, firstName, lastName, diveExperience } = user
  return (
    <div className='padi-nav-header__menu-wrapper'>
      <div className='padi-nav-header__menu-column padi-nav-header__menu-column_logged-user'>
        <ul className='padi-nav-header__menu-list'>
          <li className='padi-nav-header__menu-list-item padi-nav-header__menu-list-item_title'>
            <FormattedMessage defaultMessage='My Account' />
          </li>
          <li className='padi-nav-header__menu-list-item'>
            <a
              data-navigation-elements='data-navigation-elements'
              data-region-identifier='menu-main-account-pro'
              href={createPadiProLocalizedUrl('account')}
              className='padi-nav-header__menu-list-item-link'
            >
              <FormattedMessage defaultMessage='Login & Password' />
            </a>
          </li>
          <li className='padi-nav-header__menu-list-item'>
            <a
              data-navigation-elements='data-navigation-elements'
              data-region-identifier='menu-main-account-pro'
              href={createPadiProLocalizedUrl('memberPlans')}
              className='padi-nav-header__menu-list-item-link'
            >
              <FormattedMessage defaultMessage='Membership' />
            </a>
          </li>
          <li className='padi-nav-header__menu-list-item'>
            <a
              data-navigation-elements='data-navigation-elements'
              data-region-identifier='menu-main-account-pro'
              href={createPadiProLocalizedUrl('settings')}
              className='padi-nav-header__menu-list-item-link'
            >
              <FormattedMessage defaultMessage='Preferences' />
            </a>
          </li>
          <li className='padi-nav-header__menu-list-item'>
            <a
              data-navigation-elements='data-navigation-elements'
              data-region-identifier='menu-main-account-pro'
              href={window.logout_url}
              className='padi-nav-header__menu-list-item-link'
            >
              <FormattedMessage defaultMessage='Log Out' />
            </a>
          </li>
        </ul>
      </div>
      <div className='padi-nav-header__menu-column padi-nav-header__menu-column_logged-user'>
        <a
          data-navigation-elements='data-navigation-elements'
          data-region-identifier='menu-main-account-pro'
          href={createPadiProLocalizedUrl('account')}
          className='padi-nav-header__user-section'
        >
          <img
            loading='lazy'
            className='padi-nav-header__user-section-avatar'
            src={avatar?.HeaderAvatar}
          />
          <div className='padi-nav-header__user-details'>
            <span className='padi-nav-header__user-name'>
              {firstName} {lastName}
            </span>
            {diveExperience?.certificationLevel && (
              <span className='padi-nav-header__user-role'>
                {diveExperience?.certificationLevelTitle}
              </span>
            )}
          </div>
        </a>
        <ul className='padi-nav-header__menu-list padi-nav-header__menu-list_with-separator-mobile'>
          <li className='padi-nav-header__menu-list-item'>
            <a
              data-navigation-elements='data-navigation-elements'
              data-region-identifier='menu-main-account-pro'
              href={createPadiProLocalizedUrl('dashboard')}
              className='padi-nav-header__menu-list-link'
            >
              <FormattedMessage defaultMessage='Pros Site' />
            </a>
          </li>
          <li className='padi-nav-header__menu-list-item'>
            <a
              data-navigation-elements='data-navigation-elements'
              data-region-identifier='menu-main-account-pro'
              href={createPadiProLocalizedUrl('diveJobs')}
              className='padi-nav-header__menu-list-item-link'
            >
              <FormattedMessage defaultMessage='Job Board' />
            </a>
          </li>
          <li className='padi-nav-header__menu-list-item'>
            <a
              data-navigation-elements='data-navigation-elements'
              data-region-identifier='menu-main-account-pro'
              href={createPadiProLocalizedUrl('processing')}
              className='padi-nav-header__menu-list-item-link'
            >
              <FormattedMessage defaultMessage='Online Processing Center' />
            </a>
          </li>
          <li className='padi-nav-header__menu-list-item'>
            <a
              data-navigation-elements='data-navigation-elements'
              data-region-identifier='menu-main-account-pro'
              href={createPadiProLocalizedUrl('trainingHub')}
              className='padi-nav-header__menu-list-item-link'
            >
              <FormattedMessage defaultMessage='Training Hub' />
            </a>
          </li>
          <li className='padi-nav-header__menu-list-item'>
            <a
              data-navigation-elements='data-navigation-elements'
              data-region-identifier='menu-main-account-pro'
              href={createPadiProLocalizedUrl('shop')}
              className='padi-nav-header__menu-list-item-link'
            >
              <FormattedMessage defaultMessage='Shop Now' />
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}

UserLoggedInPro.propTypes = {
  user: PropTypes.object.isRequired,
}
