import { Fragment } from 'react'
import { isLoggedIn } from '../../../utils'
import { FormattedMessage } from 'react-intl'
import useUserFromStorage from './hooks/useUserFromStorage'

export default function PadiHeaderAccountAvatar() {
  const isUserAuthenticated = isLoggedIn()
  const user = useUserFromStorage()
  return isUserAuthenticated && user ? (
    <Fragment>
      <img
        className='padi-nav-header__profile-picture'
        src={user?.avatar?.HeaderAvatar}
      />
      <span className='padi-nav-header__title-with-icon'>
        {user?.firstName}
      </span>
    </Fragment>
  ) : (
    <Fragment>
      <i className='padi-nav-header__icon padi-icons padi-icons--single-user' />
      <span className='padi-nav-header__title-with-icon'>
        <FormattedMessage defaultMessage='Sign In' />
      </span>
    </Fragment>
  )
}
