import { useEffect, useState } from 'react'
import store2 from 'store2'

export default function useUserFromStorage() {
  const [user, setUser] = useState(null)
  useEffect(() => {
    const handleStorage = () => {
      if(store2.get('user')) {
        setUser(store2.get('user'))
      }
    }

    window.addEventListener('storage', handleStorage)
    return () => window.removeEventListener('storage', handleStorage)
  }, [])

  return user
}
