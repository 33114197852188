import { FormattedMessage } from 'react-intl'
import { createUrl } from '../../../localized-urls'
import cx from 'classnames'
import styles from './AccountMenu.module.scss'

export default function NonLoggedInMenu() {
  return (
    <li className={cx('small-menu', styles.menu)}>
      <span className='guest'>
        <i className='font-icons people-icon' />
      </span>
      <div className={cx('drop-down-menu', styles.dropDownMenu)}>
        <div className='group'>
          <h4><FormattedMessage defaultMessage='Account' /></h4>
          <ul>
            <li>
              <a
                data-navigation-elements='data-navigation-elements'
                data-region-identifier='menu-travelmain-account'
                href={createUrl('login')}
              ><FormattedMessage defaultMessage='Sign in / create an account' /></a>
              <i className='font-icons people-icon' />
            </li>
            <li>
              <a
                data-navigation-elements='data-navigation-elements'
                data-region-identifier='menu-travelmain-account'
                href={createUrl('bookings')}
              >
                <FormattedMessage defaultMessage='My Bookings' />
              </a>
              <i className='font-icons suitcase-icon' />
            </li>
            <li>
              <a
                data-navigation-elements='data-navigation-elements'
                data-region-identifier='menu-travelmain-account'
                href={createUrl('wishlist')}
              ><FormattedMessage defaultMessage='View my wishlist' />f</a>
              <i className='font-icons heart-icon' />
            </li>
            <li>
              <a
                data-navigation-elements='data-navigation-elements'
                data-region-identifier='menu-travelmain-account'
                href='https://logbook.diviac.com/'
              >
                <FormattedMessage defaultMessage='Go to my Diviac logbook' /></a>
            </li>
          </ul>
          <p className='text-left mtm'>
            <FormattedMessage defaultMessage='Create an account to set your preferences, save items to your wishlist and manage your bookings from any device at any time' />
          </p>
        </div>
      </div>
    </li>
  )
}
