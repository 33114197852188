import { FormattedMessage } from 'react-intl'
import { createPadiLearningLocalizedUrl, createPadiProLocalizedUrl } from '../../../localized-urls'
import { createLoginUrl } from '../../../utils'

export default function UserNotLoggedIn() {
  const loginHandler = (e) => {
    e.preventDefault()
    window.location.href = createLoginUrl()
  }
  return (
    <div className='padi-nav-header__menu-wrapper'>
      <div className='padi-nav-header__menu-column'>
        <ul className='padi-nav-header__menu-list'>
          <li
            className='padi-nav-header__menu-list-item padi-nav-header__menu-list-item_title'
          >
            <FormattedMessage defaultMessage='Account' />
          </li>
          <li className='padi-nav-header__menu-list-item'>
            <a
              data-navigation-elements='data-navigation-elements'
              data-region-identifier='menu-main-account-anonymous'
              href={createPadiLearningLocalizedUrl('certifications')}
              className='padi-nav-header__menu-list-item-link'
            >
              <FormattedMessage defaultMessage='Certifications' />
            </a>
          </li>
          <li className='padi-nav-header__menu-list-item'>
            <a
              data-navigation-elements='data-navigation-elements'
              data-region-identifier='menu-main-account-anonymous'
              href={createPadiLearningLocalizedUrl('dashboard')}
              className='padi-nav-header__menu-list-item-link'
            >
              <FormattedMessage defaultMessage='eLearning Portal' />
            </a>
          </li>
          <li className='padi-nav-header__menu-list-item'>
            <a
              data-navigation-elements='data-navigation-elements'
              data-region-identifier='menu-main-account-anonymous'
              href={createPadiLearningLocalizedUrl('logbook')}
              className='padi-nav-header__menu-list-item-link'
            >
              <FormattedMessage defaultMessage='Logbook' />
            </a>
          </li>
          <li className='padi-nav-header__menu-list-item'>
            <a
              data-navigation-elements='data-navigation-elements'
              data-region-identifier='menu-main-account-anonymous'
              href={createPadiLearningLocalizedUrl('settings')}
              className='padi-nav-header__menu-list-item-link'
            >
              <FormattedMessage defaultMessage='Preferences' />
            </a>
          </li>
          <li className='padi-nav-header__menu-list-item'>
            <a
              data-navigation-elements='data-navigation-elements'
              data-region-identifier='menu-main-account-anonymous'
              href={createPadiProLocalizedUrl('dashboard')}
              className='padi-nav-header__menu-list-link'
            >
              <FormattedMessage defaultMessage='Pros Site' />
            </a>
          </li>
        </ul>
      </div>

      <div className='padi-nav-header__menu-column'>
        <a
          data-navigation-elements='data-navigation-elements'
          data-region-identifier='menu-main-account-anonymous'
          href={createPadiLearningLocalizedUrl('mobileApps')}
        >
          <img
            loading='lazy'
            src={`${window.staticPath}travel_dist/images/account/image_1.png`}
            className='padi-nav-header__picture'
          />
        </a>
        <ul className='padi-nav-header__menu-list'>
          <li
            className='padi-nav-header__menu-list-item padi-nav-header__menu-list-item_title'
          >
            <a
              data-navigation-elements='data-navigation-elements'
              data-region-identifier='menu-main-account-anonymous'
              href={createPadiLearningLocalizedUrl('mobileApps')}
              className='padi-nav-header__menu-list-item-link'
            ><FormattedMessage defaultMessage='PADI Adventures App' /></a>
          </li>
          <li
            className='padi-nav-header__menu-list-item padi-nav-header__menu-list-item_description'
          >
            <FormattedMessage defaultMessage='All the information you need to plan your dive vacations anywhere in the world.' />
          </li>
          <li className='padi-nav-header__menu-list-item'>
            <a
              data-navigation-elements='data-navigation-elements'
              data-region-identifier='menu-main-account-anonymous'
              href={createPadiLearningLocalizedUrl('mobileApps')}
              className='padi-nav-header__menu-list-link'
            >
              <FormattedMessage defaultMessage='Learn more' />
            </a>
          </li>
        </ul>
        <div className='padi-nav-header__menu-list padi-nav-header__menu-list_desktop'>
          <a
            data-navigation-elements='data-navigation-elements'
            data-region-identifier='menu-main-account-anonymous'
            href='https://app.appsflyer.com/id1497105546?pid=web_padi&c=menu_main'
          >
            <img
              loading='lazy'
              src={`${window.staticPath}travel_dist/images/app_store_badge.svg`}
              className='padi-nav-header__app-badge'
            />
          </a>
          <a
            data-navigation-elements='data-navigation-elements'
            data-region-identifier='menu-main-account-anonymous'
            href='https://app.appsflyer.com/com.padi?pid=web_padi&c=menu_main'
          >
            <img loading='lazy'
              src={`${window.staticPath}travel_dist/images/play_store_badge.svg`}
              className='padi-nav-header__app-badge'
            />
          </a>
        </div>
      </div>

      <div className='padi-nav-header__menu-column'>
        <a
          data-navigation-elements='data-navigation-elements'
          data-region-identifier='menu-main-account-anonymous'
          href="{% trans 'https://www.padi.com/mobile-apps' %}"
        >
          <img loading='lazy'
            src={`${window.staticPath}travel_dist/images/account/image_2.png`}
            className='padi-nav-header__picture'
          />
        </a>
        <ul className='padi-nav-header__menu-list'>
          <li
            className='padi-nav-header__menu-list-item padi-nav-header__menu-list-item_title'
          >
            <a
              data-navigation-elements='data-navigation-elements'
              data-region-identifier='menu-main-account-anonymous'
              href={createPadiLearningLocalizedUrl('mobileApps')}
              className='padi-nav-header__menu-list-item-link'
            >
              <FormattedMessage defaultMessage='PADI App' />
            </a>
          </li>
          <li
            className='padi-nav-header__menu-list-item padi-nav-header__menu-list-item_description'
          >
            <FormattedMessage defaultMessage='Access to tools and resources at your fingertips, curated to enhance your diving experience.' />
          </li>
          <li className='padi-nav-header__menu-list-item'>
            <a
              data-navigation-elements='data-navigation-elements'
              data-region-identifier='menu-main-account-anonymous'
              href={createPadiLearningLocalizedUrl('mobileApps')}
              className='padi-nav-header__menu-list-link'
            >
              <FormattedMessage defaultMessage='Learn more' />
            </a>
          </li>
        </ul>
        <div className='padi-nav-header__menu-list padi-nav-header__menu-list_desktop'>
          <a
            data-navigation-elements='data-navigation-elements'
            data-region-identifier='menu-main-account-anonymous'
            href='https://app.appsflyer.com/id1490797188?pid=web_padi&c=menu_main'
          >
            <img loading='lazy'
              src={`${window.staticPath}travel_dist/images/app_store_badge.svg`}
              className='padi-nav-header__app-badge'
            />
          </a>
          <a
            data-navigation-elements='data-navigation-elements'
            data-region-identifier='menu-main-account-anonymous'
            href='https://app.appsflyer.com/com.duns.padiapp?pid=web_padi&c=menu_main'
          >
            <img loading='lazy'
              src={`${window.staticPath}travel_dist/images/play_store_badge.svg`}
              className='padi-nav-header__app-badge'
            />
          </a>
        </div>
      </div>
      <div className='padi-nav-header__menu-column'>
        <ul className='padi-nav-header__menu-list'>
          <li
            className='padi-nav-header__menu-list-item padi-nav-header__menu-list-item_title'
          >
            <FormattedMessage defaultMessage='My profile' />
          </li>
          <li
            className='padi-nav-header__menu-list-item padi-nav-header__menu-list-item_description'
          >
            <a
              data-navigation-elements='data-navigation-elements'
              data-region-identifier='menu-main-account-anonymous'
              onClick={(e) => { loginHandler(e) }}
              className='padi-nav-header__button-blue'
            >
              <FormattedMessage defaultMessage='Sign in' />
            </a>
          </li>
          <li
            className='padi-nav-header__menu-list-item padi-nav-header__menu-list-item_description'
          >
            <a
              data-navigation-elements='data-navigation-elements'
              data-region-identifier='menu-main-account-anonymous'
              href={window.signup_url}
              className='padi-nav-header__button-blue-inverted'
            >
              <FormattedMessage defaultMessage='Create account' />
            </a>
          </li>
          <li
            className='padi-nav-header__menu-list-item padi-nav-header__menu-list-item_title padi-nav-header__menu-list-item_mobile-padding'
          >
            <FormattedMessage defaultMessage='Join our community!' />
          </li>
          <li
            className='padi-nav-header__menu-list-item padi-nav-header__menu-list-item_description'
          >
            <FormattedMessage defaultMessage='As a diver, with a PADI account you can access your eLearning along with many more tools to help you on your adventures!' />
          </li>
          <li className='padi-nav-header__menu-list-item'>
            <a
              data-navigation-elements='data-navigation-elements'
              data-region-identifier='menu-main-account-anonymous'
              href={createPadiLearningLocalizedUrl('membership')}
              className='padi-nav-header__menu-list-link'
            >
              <FormattedMessage defaultMessage='Learn more' />
            </a>
          </li>
        </ul>
      </div>
    </div>

  )
}
