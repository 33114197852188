import { createRoot } from 'react-dom/client'
import { createStore, combineReducers } from 'redux'
import storage from 'store2'
import API, { configure } from '../api'
import { Provider } from 'react-redux'
import { IntlProvider, createIntl, createIntlCache } from 'react-intl'
import { numberOfTrips } from '../actions/search-actions'
import {
  selectedCurrency,
  pageLoader,
  itineraryPopupOpen,
  itineraryPopupData,
  setUser,
  user,
} from '../actions/main-actions'
import { submitReview } from '../components/popups/ReviewPopup'
import {
  headerDirective,
  smoothScroll,
  mobileHeader,
  stickyNavigation,
  wishlistDirective,
  hideTopBanner,
  headerDestinationToggle,
  changeAffiliateDirective,
  selectAffiliateLink,
  removeTargetBlankOnMobile,
  mobileCollapseItems,
  padiHeaderScripts,
  translateButtonToggle,
  trackClickEvents,
  hideIntercom,
} from '../directives'
import { navigationClicks } from '../components/dive-guides/gtm-events'
import HeaderTypeahead from '../components/header/HeaderTypeahead'
import HeaderSearchSection from '../components/header/HeaderSearchSection'
import {
  getLanguage,
  setAffiliateCookie,
  parseQuery,
  cleanQueryParameter,
  getRefreshToken,
  setIdToken,
  getIdToken,
  getTranslations,
  getCookie,
  deleteCookie,
  linkShopToAccount,
} from 'utils'
import { toast } from 'react-toastify'
import 'babel-polyfill'
import 'whatwg-fetch'
import 'react-toastify/dist/ReactToastify.css'

import '@formatjs/intl-getcanonicallocales/polyfill'
import '@formatjs/intl-locale/polyfill'
import '@formatjs/intl-pluralrules/polyfill'
import '@formatjs/intl-pluralrules/locale-data/en'
import 'finally-polyfill'

import IndividualBooking from '../components/footer/IndividualBooking'
import LanguageWarningPopup from '../components/popups/LanguageWarningPopup'
import moment from 'moment'
import ErrorHandler from '../components/ErrorHandler'
import AffiliateBar from '../components/header/AffiliateBar'
import ToggleAccountMenu from '../components/header/account/ToggleAccountMenu'
import PadiHeaderAccountAvatar from '../components/header/padiHeader/PadiHeaderAccountAvatar'
import PadiHeaderAccountSection from '../components/header/padiHeader/PadiHeaderAccountSection'
// import * as fx from '../libs/money'

if(process.env.SENTRY_KEY) {
  Sentry.init({
    dsn: process.env.SENTRY_KEY,
    ignoreErrors: [
      'AbortError',
      'UnhandledRejection',
      'Unexpected token o in JSON at position 1',
      'Anonymous function(/gtm.js)',
      "undefined is not an object (evaluating 'd.appendChild')",
      'chrome-extension',
      'gtm',
      'Illegal invocation',
      "Can't find variable: setIOSParameters",
      '(app-modern)',
      'd is undefined',
    ],
  })
}

let store = createStore(
  combineReducers({
    pageLoader,
    numberOfTrips,
    selectedCurrency,
    itineraryPopupOpen,
    itineraryPopupData,
    user,
  }),
)

const choosedDate = storage('date') ? storage('date')[0] : null
if(!choosedDate) {
  const date = moment().add(4, 'weeks').format('YYYY-MM-DD')
  storage('date', [date, moment(date).add(7, 'days').format('YYYY-MM-DD')])
}

const roomParams = storage.get('roomParams')
if(!roomParams || roomParams?.students) {
  const roomParams = {
    details: [
      {
        divers: 2,
        students: 0,
        nonDivers: 0,
        rooms: 1,
      },
    ],
    guests_split: [
      {
        divers: 2,
        students: 0,
        nonDivers: 0,
        rooms: 1,
      },
    ],
    id: 'auto',
    divers: 2,
    nonDivers: 0,
    students: 0,
    rooms: 1,
    totalGuests: 2,
  }
  storage.set('roomParams', roomParams)
}

const token = getIdToken()
// Previous search handle
const previousSearch = storage('previousSearch')

if(previousSearch && token) {
  previousSearch.forEach(function(searchData) {
    API(`account/search-requests/`, false, true).post({
      searchData,
    })
  })
  storage.remove('previousSearch')
}

if(token) {
  const reviewData = storage.session('reviewData')
  if(reviewData) {
    storage.session.remove('reviewData')
    submitReview(reviewData.shopSlug, reviewData)
  }
  storage.set('token', token)
  API('account/get-userdata/')
    .get()
    .then((data) => {
      const currentUser = storage('user')

      store.dispatch(
        setUser({ ...data.user, affiliate: data.user.affiliates[0] }),
      )

      if(!currentUser && data.user) {
        storage.set('user', {
          ...data.user,
          affiliate: data.user.affiliates[0],
        })
      } else if(data?.user?.id !== currentUser?.id) {
        storage.set('user', {
          ...data.user,
          affiliate: data.user.affiliates[0],
        })
        window.location.reload()
      }

      const adventureAid = getCookie('padi_aid_adventures')
      if(adventureAid) {
        linkShopToAccount(adventureAid)
        deleteCookie('padi_aid_adventures')
      }
    })
  refreshToken()
  setInterval(() => refreshToken(), 30 * 60 * 1000) // 30 min
}

function refreshToken() {
  const token = getRefreshToken()
  if(!token) {
    return false
  }
  API(process.env.COGNITO_REFRESH_URL, false, true, true)
    .post({
      refreshToken: token,
      clientId: getCookie('padi_aud') || process.env.COGNITO_ID,
    })
    .then(function(data) {
      setIdToken({ token: data.idToken, expires: data.expiresIn })
    })
}

setAffiliateCookie()

let language = getLanguage()
let messages = {}
toast.configure()

window.store = store
window.padiLanguage = language
configure(store)

// window.fx = fx

if(language !== 'en') {
  getTranslations(language, renderApp)
} else {
  renderApp()
}

function renderApp(translatedStrings) {
  const currentUser = storage('user')
  if(translatedStrings) {
    messages = translatedStrings
  }
  const intlCache = createIntlCache()
  const intl = createIntl(
    {
      locale: language,
      messages,
    },
    intlCache,
  )

  const { from_lang: fromLang, intercom } = parseQuery()
  const lang = window.navigator.userLanguage || window.navigator.language

  if(intercom && intercom === 'true' && window.Intercom) {
    setTimeout(() => {
      window.Intercom('show')
    }, 1500)
  }

  renderElement('login-menu', ToggleAccountMenu)
  renderElement('account-avatar', PadiHeaderAccountAvatar)
  renderElement('account-section', PadiHeaderAccountSection)
  renderElement('typeahead-react', HeaderTypeahead)
  renderElement('react-header-search', HeaderSearchSection)
  renderElement('header-email-us', IndividualBooking)

  if(currentUser?.isShopManager && currentUser?.affiliates?.length) {
    renderElement('affiliate-bar', AffiliateBar)
  }
  // renderElement('subscribe-container', SubscribeButton)
  if(fromLang || lang === 'ja') {
    cleanQueryParameter('from_lang')

    if(!storage?.session('languageWarningShown')) {
      renderElement('warning-popup', LanguageWarningPopup, {
        lang: fromLang || lang,
      })
    }
  }

  if(window.innerWidth < 768) {
    mobileCollapseItems()
  }

  headerDirective()
  mobileHeader()
  stickyNavigation()
  wishlistDirective(intl)
  hideTopBanner()
  smoothScroll()
  headerDestinationToggle()
  changeAffiliateDirective()
  selectAffiliateLink()
  removeTargetBlankOnMobile()
  padiHeaderScripts(window.isDsl)
  navigationClicks()
  translateButtonToggle()
  trackClickEvents()
  hideIntercom()
}

function renderElement(elem, ComponentToRender, props) {
  let element
  if(typeof elem === 'string') {
    element = document.getElementById(elem)
  } else {
    element = elem
  }

  if(element) {
    const root = createRoot(element)
    root.render(
      <IntlProvider
        locale={language === 'ach' ? 'en' : language}
        messages={messages}
        textComponent='span'
      >
        <Provider store={store}>
          <ErrorHandler>
            <ComponentToRender {...props} />
          </ErrorHandler>
        </Provider>
      </IntlProvider>,
    )
  }
}
